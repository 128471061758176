<template>
  <div class="inline-flex font-deco-alt">
    <a
      v-if="
        props.link == '' ||
        props.link.charAt(0) != '/' ||
        props.link.startsWith('http') ||
        props.link.startsWith('www.')
      "
      :href="props.link"
      :target="props.target"
      :class="props.classes"
      class="flex"
      @click="onBasicLinkClicked"
    >
      <div
        class="flex gap-2 place-items-center"
        :class="{
          'flex-row-reverse': props.iconPosition == EPosition.LEFT,
        }"
      >
        <span :class="hoverAnimation">
          <template v-if="props.label">{{ props.label }}</template>
          <slot v-else />
        </span>
        <FaIcon v-if="props.icon" :icon-class="props.icon" />
      </div>
    </a>

    <div v-else :class="props.classes" class="flex">
      <NuxtLink
        :to="{ path: props.link, query: props.filterParams, hash: props.hash }"
        :target="props.target"
      >
        <div
          class="flex gap-2 place-items-center"
          :class="{
            'flex-row-reverse': props.iconPosition == EPosition.LEFT,
          }"
          @click="onBasicLinkClicked"
        >
          <span :class="hoverAnimation">
            <template v-if="props.label">{{ props.label }}</template>
            <slot v-else />
          </span>
          <FaIcon v-if="props.icon" :icon-class="props.icon" />
        </div>
      </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import FaIcon from '@/components/fa-icon.vue';
import { EPosition } from '@/@types/basic-link';
/** Props */
const props = defineProps({
  label: {
    type: String,
    required: false,
    default: '',
  },
  hoverAnimation: {
    type: String,
    required: false,
    default: 'anim__underline-link anim-link',
  },
  link: {
    type: String,
    required: true,
  },
  filterParams: {
    type: Object,
    default: () => ({}),
  },
  hash: {
    type: String,
    required: false,
    default: '',
  },
  icon: {
    type: String,
    required: false,
    default: null,
  },
  iconPosition: {
    type: String,
    default: EPosition.RIGHT,
  },
  target: {
    type: String,
    required: false,
    default: '',
  },
  classes: {
    type: String,
    required: false,
    default: '',
  },
});

/** Emits */
const emit = defineEmits<{
  (e: 'click'): void;
}>();

function onBasicLinkClicked() {
  emit('click');
}
</script>
